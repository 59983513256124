<template>
  <div class="campanha p-relative">
    <!-- .campanha-preview -->
    <Simulation
      :campaign="campaign"
      :landingPageOptions="landingPageOptions"
      :linkToDonation="this.linkToDonation"
      @donate="scrollToElement"
      @calculate="updateSimulationValue"
    />
    <MemberGetMember
      :campaign="campaign"
      :landingPageOptions="landingPageOptions"
    />
    <SimulationValueFloat
      v-if="simulationValue > 0"
      :dialog="true"
      :valueToDiscount="0"
      :suggestedValue="simulationValue"
      @openSimulation="toggleSimulation"
    ></SimulationValueFloat>
    <Icon />
    <Nav :landingPageOptions="landingPageOptions" />
    <router-view @updateCampaign="updateCampaign" :landingPageOptions="landingPageOptions" />
  </div>
</template>

<script type="plain/text">
import Nav from "@/components/Nav.vue";
import Icon from "@/components/Icon.vue";
import CampaignLandingPageOptions from "@/scripts/models/campaignLandingPageOptions.model";
import Simulation from "@/components/Simulation.vue";
import MemberGetMember from "@/components/MemberGetMember.vue";
import SimulationValueFloat from "@/components/SimulationValueFloat.vue";
export default {
  nome: "campanha",
  components: {
    Nav,
    Icon,
    Simulation,
    SimulationValueFloat,
    MemberGetMember
  },
  data() {
    return {
      landingPageOptions: new CampaignLandingPageOptions(),
      linkToDonation: "project-gallery",
      simulationValue: 0,
      campaign: null
    };
  },
  created() {
    this.getSuggestedValueFromSessionStorage();
  },
  methods: {
    scrollToElement() {
      const el = this.$el.getElementsByClassName("projetos")[0];
      if (el) {
        el.scrollIntoView();
      }
    },
    getSuggestedValueFromSessionStorage() {
      this.simulationValue = sessionStorage.getItem("result");
    },
    updateSimulationValue(data) {
      this.simulationValue = data;
    },
    toggleSimulation() {
      this.landingPageOptions.simulationDrawer = !this.landingPageOptions
        .simulationDrawer;
    },
    updateCampaign(campaign) {
      this.campaign = campaign;
    }
  }
};
</script>
